<template>
    <div class="main-talk training">
        <div class="talk-menu-wrap">
            <ul class="menu-list-wrap">
                <li class="menu-list">
                    <router-link to="/training/sub?episode=1" class="menu-link green" @mouseover.prevent="_hover" @mouseout.prevent="_hoverOut">
                        <figure>
                            <img src="../../assets/images/training/icon_menu2.jpg" alt="" />
                        </figure>
                        <em>혀</em>
                    </router-link>
                </li>
                <li class="menu-list">
                    <router-link to="/training/sub?episode=2" class="menu-link red" @mouseover.prevent="_hover" @mouseout.prevent="_hoverOut">
                        <figure>
                            <img src="../../assets/images/training/icon_menu1.jpg" alt="" />
                        </figure>
                        <em>입술​</em>
                    </router-link>
                </li>
                <li class="menu-list">
                    <router-link to="/training/sub?episode=3" class="menu-link blue" @mouseover.prevent="_hover" @mouseout.prevent="_hoverOut">
                        <figure>
                            <img src="../../assets/images/training/icon_menu3.jpg" alt="" />
                        </figure>
                        <em>볼</em>
                    </router-link>
                </li>
                <li class="menu-list">
                    <router-link to="/training/sub?episode=4" class="menu-link green" @mouseover.prevent="_hover" @mouseout.prevent="_hoverOut">
                        <figure>
                            <img src="../../assets/images/training/icon_menu4.jpg" alt="" />
                        </figure>
                        <em>구개</em>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { router } from '../../router';

export default {
    name: "trainingMain",
    beforeMount() {
        console.log(this.$cookie.getCookie('training-token'));
        if (this.$cookie.getCookie('training-token') === null) this.$router.push('/');
    },
    computed: {},
    methods: {
        _hover(e) {
            let _target = e.target;
            if (_target.className.indexOf('menu-link') === -1) _target = e.target.closest('.menu-link');
        },

        _hoverOut(e) {
            let _target = e.target;
            if (_target.className.indexOf('menu-link') === -1) _target = e.target.closest('.menu-link');
        }
    }
}
</script>

<style>
    @import url(../../talk.css);

    .main-talk.training {
        background: #fff;
    }

    .main-talk.training .talk-menu-wrap .menu-list-wrap .menu-list {
        width: 45%
    }

    .training .menu-list figure {
        margin-bottom: 2rem;
    }

    .main-talk.training .talk-menu-wrap .menu-list-wrap .menu-link {
        width: 100%;
    }

    .main-talk.training .talk-menu-wrap .menu-list-wrap .menu-link figure img {
        width: 120px;
        height: 120px;
    }

    @media (max-width: 1440px) {
        .main-talk.training .talk-menu-wrap .menu-list-wrap .menu-link figure img {
            width: 8.3333vw;
            height: 8.3333vw;
        }
    }
</style>